.create-employee {
  flex: auto;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.create-employee h2 {
  margin-bottom: 1rem;
  color: var(--second-color);
}

.create-employee-form {
  display: flex;
  flex-direction: column;
}

.create-employee-form .form-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.create-employee-form .form-row label {
  flex: 1;
  font-weight: bold;
  color: #333;
}

.create-employee-form .form-row input {
  flex: 2;
  padding: 0.5rem;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.create-employee-form .form-row input.editable {
  background-color: #fff;
  cursor: text;
}

.create-employee-form .form-row input:focus {
  border-color: var(--second-color);
  outline: none;
}

.upload-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--second-color);
  color: #fff;
  border-radius: 0.25rem;
  border: 2px solid var(--second-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.upload-button:hover {
  background-color: transparent;
  color: var(--second-color);
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.save-button,
.cancel-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--second-color);
  color: #fff;
  border-radius: 0.25rem;
  border: 2px solid var(--second-color);
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.icon-buttons {
  margin-right: 0.4rem;
}

.save-button:hover {
  background-color: transparent;
  color: var(--second-color);
}

.cancel-button {
  border-color: var(--red-color);
  background-color: var(--red-color);
}

.cancel-button:hover {
  background-color: transparent;
  color: var(--red-color);
}

.profile-picture-preview {
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  text-align: center;
}

.profile-picture-preview img {
  max-width: 150px;
  max-height: 150px;
}

.profile-picture-preview .placeholder {
  color: #888;
}

.kpi-container {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.kpi-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.kpi-table th,
.kpi-table td {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}

.kpi-section {
  margin-bottom: 20px;
}

.subtotal-row td {
  font-weight: bold;
  background-color: #e9ecef;
}

.total-row {
  margin-top: 20px;
  font-weight: bold;
}

.total-table {
  width: 100%;
  border-collapse: collapse;
}

.total-table th,
.total-table td {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: left;
}

.grand-total-row {
  background-color: #d7d7d7;
}

.rating-scale {
  display: flex;
  flex-direction: column;
}

.rating-scale div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.rating-badge {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  color: white;
  margin-right: 8px;
}

.rating-1 {
  background-color: #dc3545;
}

.rating-2 {
  background-color: #ffc107;
  margin-top: 5px;
}

.rating-3 {
  background-color: #28a745;
  margin-top: 5px;
}

.rating-4 {
  background-color: #17a2b8;
  margin-top: 5px;
}

.rating-5 {
  background-color: #007bff;
  margin-top: 5px;
}

.kpi-table .description-col {
  width: 20%;
}

.kpi-table .goal-col {
  width: 15%;
}

.kpi-table .rating-scale-col {
  width: 25%;
}

.kpi-table .supporting-evidence-col {
  width: 10%;
}

.manager-comments-col {
  width: 20%;
}

.achievements-col {
    width: 15%;
}

.dashboard-links {
  display: flex;
  width: 100%;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: none;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  font-family: var(--second-font);
}

.dashboard-links:hover {
  background-color: var(--first-hover-color);
}

.dashboard-links.active {
  background-color: var(--first-hover-color);
}

.icon {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

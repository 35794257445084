.personal-details {
  flex: auto;
}

.personal-details h2 {
  margin-bottom: 0.5rem;
}

.personal-details-form {
  display: flex;
  flex-direction: column;
}

.personal-details-form .form-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.personal-details-form .form-row label {
  flex: 1;
  font-weight: bold;
}

.personal-details-form .form-row input {
  flex: 2;
  padding: 0.2rem;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.personal-details-form .form-row input.read-only {
  background-color: #f9f9f9;
  cursor: default;
}

.personal-details-form .form-row input.editable {
  background-color: #fff;
  cursor: text;
}

.personal-details-form .form-row input:focus {
  border-color: var(--table-color);
  outline: none;
}

.personal-details-form .form-row input {
  font-size: 0.9rem;
  padding: 0.3rem;
}

.form-buttons {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.save-button,
.edit-button,
.cancel-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--second-color);
  color: var(--body-color);
  border-radius: 0.25rem;
  border: 2px solid var(--second-color);
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-buttons {
  margin-right: 0.4rem;
}

.save-button:hover,
.edit-button:hover {
  background-color: transparent;
  color: var(--second-color);
}

.cancel-button {
  border-color: var(--red-color);
  background-color: var(--red-color);
}

.cancel-button:hover {
  background-color: transparent;
  color: var(--red-color);
}

.hidden {
  display: none;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
}

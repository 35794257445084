/* ManageReviewDashboard.css */

:root {
  --first-color: hsl(101, 46%, 41%);
  --first-color-hover: hsl(101, 46%, 35%);
  --red-color: hsl(0, 46%, 50%);
  --red-color-hover: hsl(0, 46%, 45%);
  --card-bg-color: #ffffff;
  --card-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --text-color: #333333;
  --subheading-color: #555555;
  --table-header-bg: #f1f1f1;
  --table-border-color: #ddd;
  --button-padding: 0.75rem 1.5rem;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.btn {
  font-size: 1rem;
  padding: var(--button-padding);
  margin: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn-success {
  background-color: var(--first-color);
  color: white;
}

.btn-danger {
  background-color: var(--red-color);
  color: white;
  margin-left: 2%;
}

.btn-success:hover {
  background-color: var(--first-color-hover);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-danger:hover {
  background-color: var(--red-color-hover);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.manage-reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.manage-reviews-card {
  width: 100%;
  max-width: 800px;
  background-color: var(--card-bg-color);
  padding: 30px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  text-align: center;
  margin: 20px;
}

.headings {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--text-color);
}

.sub-heading {
  font-size: 22px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
  color: var(--subheading-color);
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.period-buttons {
  padding: var(--button-padding);
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.current-period {
  background-color: #e0f7fa;
  padding: 15px;
  border: 1px solid #b2ebf2;
  border-radius: 6px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  text-align: left;
}

.current-period div {
  margin-bottom: 5px;
}

.review-periods-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.review-periods-table th, .review-periods-table td {
  padding: 12px;
  border: 1px solid var(--table-border-color);
  text-align: left;
}

.review-periods-table th {
  background-color: var(--table-header-bg);
}

.review-periods-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.review-periods-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .manage-reviews-card {
    width: 100%;
    padding: 20px;
    margin: 0;
    border-radius: 0;
  }

  .headings {
    font-size: 24px;
  }

  .sub-heading {
    font-size: 20px;
  }

  .review-periods-table th, .review-periods-table td {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .manage-reviews-card {
    padding: 15px;
  }

  .headings {
    font-size: 20px;
  }

  .sub-heading {
    font-size: 18px;
  }

  .review-periods-table th, .review-periods-table td {
    padding: 8px;
  }

  .button-container {
    flex-direction: column;
  }

  .btn-danger {
    margin-left: 0;
    margin-top: 10px;
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.header {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--second-color);
  z-index: var(--z-fixed);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
}

.nav__logo {
  color: var(--first-color);
  transition: color 0.4s;
  font-size: var(--h2-font-size);
  font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
  border: 2px solid transparent;
}

.nav__link:hover {
  color: var(--first-color);
  border-bottom: 2px solid var(--first-color);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}

.nav__menu {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
}

.nav__menu.show-menu {
  display: block;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

#nav-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

#nav-toggle {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.nav__cta:hover {
  color: var(--first-color);
  background-color: transparent;
  border-color: var(--first-color);
}

@media screen and (max-width: 1150px) {
  .nav__menu {
    display: none;
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 1%, 58%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 75%;
    height: 100vh;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
    z-index: 10;
  }

  .nav__menu.show-menu {
    display: flex;
    right: 0;
  }

  .nav__toggle,
  .nav__close {
    display: block;
  }

  .nav__item,
  .nav__link {
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
  }

  .nav__list {
    width: 100%;
    display: flex;
    background-color: var(--second-color);
    padding: 2%;
  }

  .nav__menu {
    align-items: start;
  }

  .nav__link {
    border: 2px solid transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0.75rem 1.5rem;
  }

  .nav__link:hover {
    border-bottom-color: var(--first-color);
  }

  .nav__cta:hover {
    color: var(--first-color);
    background-color: transparent;
    border: 2px solid var(--first-color);
  }

  .nav {
    height: calc(var(--header-height) + 1rem);
    position: relative;
    width: 100%;
    margin: 0;
    padding: 2%;
  }
}

@media screen and (min-width: 1150px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
    position: relative;
    width: 100%;
    margin: 0;
    padding: 2%;
  }

  .nav__logo {
    width: 15%;
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__menu {
    display: flex;
    justify-content: right;
    width: 100%;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
    display: flex;
    justify-content: right;
    width: 100%;
  }
}

.card-container {
  perspective: 1000px;
  width: 100%;
  max-width: 30rem;
}

.toggle-span {
  margin-right: 0.5rem;
}

.employee-card {
  position: relative;
  width: 100%;
  height: 22rem;
  text-align: left;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.employee-card.is-flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
}

.card-front {
  background-color: #fff;
  padding: 20px;
}

.card-back {
  background-color: #f8f9fa;
  transform: rotateY(180deg);
  padding: 20px;
}

.profile-pic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.profile-pic {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.upload-button {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: var(--second-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid var(--second-color);
}

.upload-icon {
  margin-right: 0.3rem;
}

.upload-button:hover {
  background-color: transparent;
}

.employee-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.employee-info h2 {
  margin: 0 0 10px 0;
  font-size: 1.5rem;
  word-wrap: break-word; /* Handles long names */
}

.employee-info p {
  margin: 5px 0;
}

.job-title {
  margin: 5px 0;
  font-style: italic;
  color: #666;
}

.location {
  margin: 5px 0;
  font-weight: bold;
  color: #333;
}

.toggle-button {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: var(--first-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button:hover {
  background-color: var(--first-color);
  filter: brightness(95%);
}

.previous-teams-container {
  max-height: 200px;
  overflow-y: auto;
}

.previous-teams-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9rem;
}

.previous-teams-table th,
.previous-teams-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.previous-teams-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.previous-teams-container::-webkit-scrollbar {
  width: 8px;
}

.previous-teams-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.previous-teams-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-container {
  width: 100%;
  border-collapse: collapse;
}
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.search-input {
  width: 50%;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.search-input:focus {
  border-color: var(--table-color);
}

.search-icon {
  position: absolute;
  left: 0.5rem;
  color: #ccc;
  transition: transform 0.2s ease-in-out;
}

.search-input:focus + .search-icon {
  transform: scale(1.1);
  color: #007bff;
}

.clear-icon {
  position: relative;
  right: 1.2rem;
  color: #ccc;
  cursor: pointer;
  z-index: 2;
}

.clear-icon:hover {
  color: #a03939;
}

.search-options-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(50% - 5px);
  z-index: 1;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
}

.search-options {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
}

.search-options li {
  padding: 0.5rem;
  cursor: pointer;
}

.search-options li:hover {
  background-color: #f0f0f0;
}

.more-results-button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  font-family: var(--first-font);
  background-color: #f0f0f0;
}

.more-results-button:hover {
  background-color: #e2e2e2;
}

table {
  border: 1px solid var(--border-color);
  background-color: white;
}

.team-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.team-row:hover {
  background-color: #d1d1d1;
  color: black;
}

.employee-details {
  overflow: hidden;
  transition: max-height 0.5s ease;
  width: 100%;
  display: block;
}

.employee-details.collapsed {
  max-height: 0;
}

.employee-details.expanded {
  max-height: 500px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

th,
td {
  padding: 0.75rem;
  text-align: left;
  border-top: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.heading-team {
  border-bottom: 3px solid var(--table-color);
}

.progress-bar {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 0.25rem;
  overflow: hidden;
  cursor: pointer;
}

.progress-bar:hover {
  filter: brightness(0.9);
}

.progress {
  height: 1rem;
  border-radius: 0.25rem;
  width: 0;
  transition: width 1s ease-in-out;
}

.title {
  font-family: var(--first-font);
  color: var(--text-color);
  background-color: white;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-bottom: none;
}

.search-icon {
  position: absolute;
  left: 0.5rem;
  color: #ccc;
  transition: transform 0.2s ease-in-out;
}

.search-input:focus + .search-icon {
  transform: scale(1.1);
  color: #007bff;
}

.active-team {
  background-color: var(--second-color);
  color: white;
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.dropdown-icon.expanded {
  transform: rotate(180deg);
}

@media (max-width: 1300px) {
  .search-input {
    width: 100%;
  }

  .search-options {
    width: calc(100% - 2px);
  }
}

@media (max-width: 700px) {
  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  th,
  td {
    padding: 5px;
    font-size: 0.9rem;
  }

  th {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.2rem;
  }
}

.employee-row {
  cursor: pointer;
}

.employee-row:hover {
  background-color: var(--third-color);
  color: black;
}
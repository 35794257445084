.main {
  width: 100%;
  height: 100%;
  display: flex;
}

.kpi_nav {
  background-color: var(--first-background-color);
  width: 15rem;
  height: 100%;
  box-sizing: border-box;
}

.dashboard-container {
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
}

.dashboard {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
}

.dashboard.hidden {
  opacity: 0;
  pointer-events: none;
}

.dashboard-links {
  display: flex;
  align-items: center;
  width: 100%;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  font-family: var(--second-font);
  transition: background-color 0.3s;
}

.dashboard-links:hover {
  background-color: var(--second-hover-color);
}

.icon {
  margin: 0 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employee_info,
.graphs-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.employee-text-value {
  margin-top: 0.3rem;
  font-weight: bold;
  font-size: 1.7rem;
  font-family: var(--first-font);
  color: var(--first-color);
}

.employee-text-description {
  font-weight: bold;
  font-size: 1.2rem;
}

.employee_info .info {
  width: calc(33.33% - 1rem);
  text-align: center;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid var(--border-color);
  margin-bottom: 1rem;
}

.graph-container {
  width: calc(50% - 1rem);
  height: 20rem;
  font-family: var(--first-font);
  color: var(--first-color);
  background-color: white;
  border: 1px solid var(--border-color);
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-employees {
  width: 100%;
}

.top-employees h2 {
  font-family: var(--first-font);
  color: var(--text-color);
  background-color: white;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-bottom: none;
}

.top-employees table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--border-color);
  background-color: white;
}

.top-employees th,
.top-employees td {
  padding: 0.5rem;
  text-align: left;
}

.top-employees td {
  border-bottom: 1px solid var(--second-border-color);
}

.top-employees th {
  color: var(--text-color);
  background-color: rgb(240, 239, 239);
  border-bottom: 3px solid var(--table-color);
}

.progress-bar {
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
}

.progress {
  height: 1rem;
  border-radius: 0.25rem;
}

@media screen and (max-width: 750px) {
  .main {
    flex-direction: column;
  }
  .kpi_nav {
    width: 100%;
    height: fit-content;
    margin-bottom: 0.5rem;
  }
  .employee_info .info,
  .graph-container,
  .top-employees table {
    width: 100%;
  }
  .graph-container {
    height: fit-content;
  }
}

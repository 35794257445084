@keyframes load {
  from {
    width: 0;
  }
  to {
    width: var(--progress-width);
  }
}

.progress-bar {
  cursor: pointer;
}

.progress-bar:hover {
  filter: brightness(0.9);
}

.progress {
  height: 1rem;
  border-radius: 0.25rem;
  animation: load 1s ease-in-out forwards;
}

@media (max-width: 700px) {
  .top-employees {
    overflow-x: auto;
  }

  .top-employees table {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .top-employees th,
  .top-employees td {
    padding: 5px;
    font-size: 0.9rem;
  }

  .top-employees th {
    font-size: 1rem;
  }

  .top-employees h2 {
    font-size: 1.2rem;
  }
}

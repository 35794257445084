.forgot-password-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.forgot-password-card {
    width: 90%;
    max-width: 600px;
    padding: 3rem;
    box-shadow: 10px 10px 35px rgba(128, 128, 128, 0.5);
    background-color: #f7f7f7;
}

.heading {
    text-align: center;
    margin-bottom: 2%;
    color: var(--first-color);
}

.user-input {
    display: grid;
}

.input-info {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
    border: 1.5px solid #e0e0e0;
    border-radius: 5px;
    text-align: center;
    outline: none;
    background-color: #fafafa;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    color: #666;
}

.input-info::placeholder {
    color: #aaa;
    font-style: normal;
}

.input-info:focus {
    border-color: var(--first-color);
    box-shadow: 0 0 5px var(--first-color);
}

.submit-button {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--first-color);
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
    background-color: var(--first-color);
    color: white;
    transform: translateY(-2px);
}

.login-nav {
    margin-top: 2%;
    display: inline-block;
    cursor: pointer;
    color: var(--first-color);
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.login-nav:hover {
    color: var(--first-color);
    text-decoration: underline;
}

.error-label {
    display: block;
    color: rgb(217, 78, 78);
    font-size: 12px;
}

.success-message {
    padding-top: 1%;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 20px 35px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 20px;
}

.manage-teams {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.manage-teams h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.add-team {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.add-team input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
}

.add-button:hover {
  background-color: #218838;
}

.existing-teams-heading {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.team-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.team-item {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.team-item.selected {
  background-color: #f1f1f1;
}

.team-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.team-header:hover {
  background-color: #e9ecef;
}

.team-header span {
  font-size: 16px;
}

.team-header button {
  padding: 4px 8px;
  font-size: 14px;
  background-color: var(--red-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.team-header button:hover {
  background-color: var(--red-color-dark);
}

.team-details {
  padding: 10px;
  border-top: 1px solid #ccc;
}

.team-details p {
  margin: 10px 0;
  font-size: 16px;
}

.edit-in-charge {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.edit-in-charge label {
  font-size: 16px;
  margin-bottom: 5px;
}

.edit-in-charge input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
  margin-top: 10px;
}

.edit-button:hover {
  background-color: #0056b3;
}

body {
  margin: 0;
  padding: 0;
}

:root {
  --header-height: 3.5rem;
  --first-color: hsl(101, 46%, 41%);
  --second-color: hsl(201, 63%, 12%);
  --third-color: #ccddf0;
  --red-color: hsl(0, 46%, 50%);
  --table-color: rgb(128, 163, 197);
  --title-color: hsl(0, 0%, 100%);
  --text-color: hsl(0, 0%, 25%);
  --border-color: hsla(0, 1%, 74%, 0.86);
  --second-border-color: hsla(0, 1%, 74%, 0.444);
  --body-color: hsl(0, 0%, 100%);
  --first-background-color: rgb(94, 94, 94);
  --second-background-color: rgb(222, 222, 222);
  --first-hover-color: rgb(64, 64, 64);
  --second-hover-color: rgba(255, 255, 255, 0.1);
  --body-font: Calibri, "Trebuchet MS", sans-serif;
  --second-font: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
}

#root {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: var(--second-background-color);
}

.employee-dashboard {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.back-button {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.7rem;
  color: var(--second-color);
  border-radius: 50%;
  border: 3px solid var(--second-color);
  padding: 0.5rem;
}

.card-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.back-button:hover {
  text-decoration: underline;
  background-color: var(--second-color);
  color: var(--body-color);
}

.dashboard-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
}

@media (max-width: 1150px) {
  .dashboard-content {
    flex-direction: column;
  }

  .personal-details {
    margin-left: 0;
    margin-top: 1rem;
  }
}
